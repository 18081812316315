import React, { useState } from "react";
import { navigate, graphql, useStaticQuery } from "gatsby";
import PageHead from "@Components/PageHead";
import Button from "@Components/Button";

import "@styles/contact.scss";

function encode(data: any) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
}

function contact() {
  const [state, setState] = useState({});

  const imageData = useStaticQuery(PageQuery);

  const handleChange = (e: React.ChangeEvent<any>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleOnClick = (e: any) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  return (
    <>
      <PageHead backgroundImg={imageData.contactUsHero.childImageSharp.fluid}>
        <h2 className="head-title">
          Let's <span>Work</span>
          <hr />
          Together
        </h2>
      </PageHead>
      <div className="container form">
        <p className="contact__tagline">
          Want us to help you launch your next Product? Or need help with
          existing one.
        </p>
        <p className="contact__subtag">We'd love to get started.</p>
        <form
          className="form__container"
          action="/thankyou/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleOnClick}
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <ul className="form__list">
            <li className="form__list-item">
              <input
                type="text"
                name="name"
                placeholder="Name"
                aria-label="Name"
                id="form__name"
                className="form__input"
                onChange={handleChange}
              />
            </li>
            <li className="form__list-item">
              <input
                type="text"
                name="businessname"
                placeholder="Business Name"
                aria-label="Business Name"
                id="form__businessname"
                className="form__input"
                onChange={handleChange}
              />
            </li>
            <li className="form__list-item">
              <input
                type="email"
                name="email"
                placeholder="Business Email"
                aria-label="Business Email"
                id="form__email"
                className="form__input"
                onChange={handleChange}
              />
            </li>
            <li className="form__list-item">
              <textarea
                name="messege"
                placeholder="How can we help?"
                aria-label="Message"
                id="form__messege"
                className="form__input"
                onChange={handleChange}
              />
            </li>
          </ul>
          <Button buttonType="submit">Let's Start</Button>
        </form>
      </div>
    </>
  );
}

export default contact;

export const PageQuery = graphql`
  query {
    contactUsHero: file(relativePath: { eq: "contactUsHero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
